/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, type ModalProps, View } from '@az/starc-ui';
import { type ReduxState } from '../../types';
import { localStorage } from '@/utils/localStorage';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import styles from './styles.module.scss';
import { SignInFlowTypeContext } from '../SignInFlowTypeContext';
import { useLabels } from '@/hooks/useLabels';
import { useIsOtpFeatureEnabled } from '@/hooks/useIsOtpFeatureEnabled';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import {
  AccountViewProvider,
  AccountNotificationProvider,
  CreateAccountProvider,
  CreateAccountContext,
  RewardsProvider,
  AccountNotificationContext,
} from '@/features/account';
import { AccountViewContext } from '@/features/account/context/account_view_context';
import { useFeatureFlag } from '@/features/globalConfig';
import { routePaths } from '@/constants/routePaths';
import type { TabIndexes } from './interface';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useStreamLineSignIn } from '@/hooks/useStreamLineSignIn';
import { sessionStorage } from '@/utils/sessionStorage';

export default function SignInModalV2({
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
  autoSubmitEmail,
  id,
  showHeaderAndFooter,
}: Props) {
  const router = useRouter();
  return (
    <AccountViewProvider
      showHeaderAndFooterProp={router.route != '/checkout'}
      onCloseModal={onClose}
    >
      <AccountNotificationProvider>
        <CreateAccountProvider>
          <SignInModalV2Inner
            activeTab={activeTab}
            isOpen={isOpen}
            setActiveTab={setActiveTab}
            onClose={onClose}
            id={id}
            autoSubmitEmail={autoSubmitEmail}
            showHeaderAndFooter={showHeaderAndFooter}
          />
        </CreateAccountProvider>
      </AccountNotificationProvider>
    </AccountViewProvider>
  );
}

const LazyModalBody = dynamic(
  () => import('./components/ModalBody/ModalBody').then((mod) => mod.ModalBody),
  {
    ssr: false,
    loading: () => (
      <View direction="column" align="center" justify="center" attributes={{ style: { flex: 1 } }}>
        <LoadingIndicator />
      </View>
    ),
  }
);

type Props = {
  isOpen: boolean;
  showHeaderAndFooter?: boolean;
  onClose(): void;
  activeTab: TabIndexes;
  setActiveTab: (a: TabIndexes) => void;
  id?: string;
  autoSubmitEmail?: string;
};

type Size = ModalProps['size'];

const LABEL_KEYS = {
  forgotPassword: 'label_forgot_password_capitalized',
  label_MyAccountLanding_Rewards_Welcome: 'label_MyAccountLanding_Rewards_Welcome',
  label_streamline_signin_create_rewards_account: 'label_streamline_signin_create_rewards_account',
  label_streamline_signin_join_az_rewards: 'label_streamline_signin_join_az_rewards',
  label_close_modal: 'label_close_streamline_modal',
  label_back: 'label_back',
} as const;

function SignInModalV2Inner({
  isOpen,
  onClose,
  activeTab,
  setActiveTab,
  id,
  showHeaderAndFooter,
  autoSubmitEmail,
}: Props) {
  const { flowType, setFlowTypeFn, setRewardsStateFn } = useContext(SignInFlowTypeContext);
  const { view, setView, reset, setUserName, setAutoSubmitEmail } = useContext(AccountViewContext);
  const { reset: resetCreateAccount } = useContext(CreateAccountContext);
  const { onClose: onCloseNotification } = useContext(AccountNotificationContext);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [changeFlowType, setChangeFlowType] = useState<string>();
  const modalWidth = isTablet ? '542px' : '540px';
  const [closeButtonTitle, setCloseButtonTitle] = useState<string>();
  const labels = useLabels(LABEL_KEYS);
  const CLOSE_BUTTON_TEXT = 'modal dialog close';
  const isTabletRewards = flowType === 'rewardsEnterId' && isTablet;
  const isFullScreenModal = isMobile || isTabletRewards;
  const deviceType = useSelector(({ appData }: ReduxState) => appData.deviceType);
  const otpFeatureEnabled = useIsOtpFeatureEnabled(); // TODO: update override in definition
  const [showModalBody, setShowModalBody] = useState(isOpen);
  const newSignInEnabled = useFeatureFlag('NEW_SIGN_IN_ENABLED') === 'true';
  const isMX = useLocale() === countryCodes.mx;
  const isStreamLineSignInEnabled = useStreamLineSignIn();
  const switchType = newSignInEnabled ? view : flowType;
  const tabletHorizontalPadding = otpFeatureEnabled ? 45 / 4 : 103 / 4;
  const { refetch: refetchHeaderData } = useHeaderData();
  const router = useRouter();
  const autoSubmittedEmail = useRef(false);

  if (autoSubmitEmail && !autoSubmittedEmail.current) {
    setAutoSubmitEmail(true);
    autoSubmittedEmail.current = true;
    setUserName(autoSubmitEmail);
  }

  const paddingBottom = () => {
    const starcModalContentPaddingPixels = 8;
    const mobileBrowserNavigationPixels = 54;
    const isPhone = deviceType === 'phone';

    if (isPhone) {
      return `${40 + mobileBrowserNavigationPixels - starcModalContentPaddingPixels}px`;
    } else {
      return '0px';
    }
  };

  useEffect(() => {
    switch (flowType) {
      case 'password':
        setCloseButtonTitle(
          `${labels.label_MyAccountLanding_Rewards_Welcome} ${CLOSE_BUTTON_TEXT}`
        );
        return;
      case 'forgotPassword':
      case 'resetPasswordEmailSent':
        setCloseButtonTitle(`${labels.forgotPassword} ${CLOSE_BUTTON_TEXT}`);
        return;
      case 'manualCreateAccount':
        setCloseButtonTitle(
          `${labels.label_streamline_signin_create_rewards_account} ${CLOSE_BUTTON_TEXT}`
        );
        return;
      case 'rewardsEnterId':
        setCloseButtonTitle(
          `${labels.label_streamline_signin_join_az_rewards} ${CLOSE_BUTTON_TEXT}`
        );
        return;
      default:
        setCloseButtonTitle(
          isMX && isStreamLineSignInEnabled
            ? labels.label_close_modal
            : `${labels.label_streamline_signin_join_az_rewards} ${CLOSE_BUTTON_TEXT}`
        );
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowType]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    const MODAL_HIDE_DELAY = 300;

    if (isOpen) {
      localStorage.setItem('signInModalSelectedTab', ['signIn', 'createAccount'][activeTab]);
      sessionStorage.setItem('signInModalOpened', 'true');
      setShowModalBody(true);
    } else {
      timeout = setTimeout(() => setShowModalBody(false), MODAL_HIDE_DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, activeTab]);

  const handleChangeTab = (activeTabIndex: TabIndexes) => setActiveTab(activeTabIndex);

  const flowTypeRef = useRef('login');
  useEffect(() => {
    flowTypeRef.current = flowType;
  }, [flowType]);

  const triggerDelayedSnackBar = () => {
    document.dispatchEvent(new CustomEvent('delayedSignInSnackBar', {}));
    localStorage.removeItem('delaySignInSnackBar');
  };

  const handleCloseModal = async () => {
    triggerDelayedSnackBar();
    if (
      flowTypeRef.current === 'signInOrCreateNewPW' ||
      ['keep_current_password', 'create_new_password'].includes(view)
    ) {
      if ([routePaths.cart, routePaths.rewardsLandingPage].includes(router.route)) {
        router.reload();
        return;
      }
      await refetchHeaderData();
    }
    onClose();
    setTimeout(() => {
      setView('login');
      reset();
      onCloseNotification();
      resetCreateAccount();
      setRewardsStateFn('initial');
      setChangeFlowType('login');
      setFlowTypeFn('login');
      resetFlagsHandler();
    }, 500);
  };

  const headerColorValue = isMobile && flowType !== 'rewardsEnterId' ? 'primary' : 'secondary';

  const resetFlagsHandler = () => setChangeFlowType(undefined);

  const handleOnClick = () => {
    onCloseNotification();
    switch (switchType) {
      case 'login':
        return (window.location.href = '/');
      case 'password':
      case 'sms_otp_code':
      case 'auto_sms_otp_code':
      case 'auto_email_otp_code':
      case 'email_otp_code':
        setFlowTypeFn('login');
        setView('login');
        reset();
        setChangeFlowType('login');
        break;
      case 'validate_otp_code_sms':
        setView('sms_otp_code');
        break;
      case 'validate_otp_code':
        setView('email_otp_code');
        break;
      case 'auto_validate_sms_otp_code':
        setView('auto_sms_otp_code');
        break;
      case 'auto_validate_email_otp_code':
        setView('auto_email_otp_code');
        break;
      case 'forgotPassword':
        setFlowTypeFn('password');
        break;
      case 'forgot_password':
        setView('password');
        break;
      case 'forgot_password_sms':
        setView('password');
        break;
      case 'validate_forgot_password':
        setView('forgot_password');
        break;
      case 'validate_forgot_password_sms':
        setView('forgot_password_sms');
        break;
      case 'resetPassword':
        setFlowTypeFn('password');
        break;
      case 'manualCreateAccount':
        setFlowTypeFn('login');
        setChangeFlowType('login');
        break;
      case 'create_account':
        setView('login');
        reset();
        resetCreateAccount();
        break;
      case 'rewardsEnterId':
        setFlowTypeFn('manualCreateAccount');
        setChangeFlowType('manualCreateAccount');
        setRewardsStateFn('initial');
        break;
      default:
        window.location.href = '/';
        break;
    }
  };

  const showBackButton = () => {
    if (newSignInEnabled) {
      switch (view) {
        case 'login':
        case 'create_new_password':
        case 'auto_create_new_password':
        case 'keep_current_password':
          return false;
        default:
          return true;
      }
    }
    return (
      isFullScreenModal &&
      ((flowType === 'password' && !otpFeatureEnabled) ||
        flowType === 'manualCreateAccount' ||
        flowType === 'rewardsEnterId')
    );
  };

  const [modalSize, setModalSize] = useState<Size>();

  useEffect(() => {
    let s: Size, m: Size, l: Size;
    s = 'full';
    m = undefined;
    l = undefined;
    if (flowType === 'rewardsEnterId') {
      m = 'full';
    }
    setModalSize({ s, m, l });
  }, [flowType]);

  return (
    <RewardsProvider>
      <Modal
        headerColor={headerColorValue}
        size={modalSize}
        attributes={{
          style: {
            maxWidth: isFullScreenModal ? '100%' : modalWidth,
            maxHeight: isFullScreenModal ? '100%' : '890px', // max height speficied by Rashad
            overflowY: 'auto',
            height: isFullScreenModal ? '' : 'auto',
            margin: 'auto',
            paddingBottom: paddingBottom(),
          },
        }}
        closeButtonTitle={closeButtonTitle}
        id={id}
        open={isOpen}
        hasCloseButton={!(isMobile && flowType === 'rewardsEnterId')}
        className={styles.signInModalV2}
        onClose={handleCloseModal}
        hasHeaderBackButton={showBackButton()}
        backButtonTitle={labels.label_back}
        onBackClick={handleOnClick}
      >
        <View
          width="100%"
          direction="column"
          padding={{
            s: [0, 0, 40 / 4, 0],
            m: [18 / 4, tabletHorizontalPadding, 60 / 4, tabletHorizontalPadding],
            l: [6 / 4, 45 / 4, 45 / 4, 45 / 4],
          }}
        >
          {isOpen || showModalBody ? (
            <LazyModalBody
              activeTab={activeTab}
              onChangeTab={handleChangeTab}
              showHeaderAndFooter={showHeaderAndFooter}
              changeFlowType={changeFlowType}
              resetFlags={resetFlagsHandler}
              onClose={handleCloseModal}
            />
          ) : null}
        </View>
      </Modal>
    </RewardsProvider>
  );
}
