/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { SignInFlowTypeContext } from '@/components/SignInFlowTypeContext';
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export type Views =
  | 'login'
  | 'password'
  | 'create_account'
  | 'email_otp_code'
  | 'sms_otp_code'
  | 'validate_otp_code'
  | 'validate_otp_code_sms'
  | 'forgot_password'
  | 'forgot_password_sms'
  | 'validate_forgot_password'
  | 'validate_forgot_password_sms'
  | 'keep_current_password'
  | 'auto_sms_otp_code'
  | 'auto_email_otp_code'
  | 'auto_validate_email_otp_code'
  | 'auto_validate_sms_otp_code'
  | 'auto_create_new_password'
  | 'create_new_password';

type AccountViewProviderType = {
  view: Views;
  userName: string;
  autoSubmitEmail: boolean;
  maskedEmail: string;
  userMaskedPhoneNumber: string;
  loading: boolean;
  disableSignIn: boolean;
  showHeaderAndFooter: boolean;
  openEmailSent: boolean;
  isModal: boolean;
  loginFlow: string;
  rememberMe: boolean;
  showFieldLevelValidation: boolean;
  isResendCode: boolean;
  setView: Dispatch<SetStateAction<Views>>;
  setUserName: Dispatch<SetStateAction<string>>;
  setAutoSubmitEmail: Dispatch<SetStateAction<boolean>>;
  setUserMaskedPhoneNumber: Dispatch<SetStateAction<string>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setDisableSignIn: Dispatch<SetStateAction<boolean>>;
  setShowHeaderAndFooter: Dispatch<SetStateAction<boolean>>;
  setOpenEmailSent: Dispatch<SetStateAction<boolean>>;
  setIsModal: Dispatch<SetStateAction<boolean>>;
  setLoginFlow: Dispatch<SetStateAction<string>>;
  setRememberMe: Dispatch<SetStateAction<boolean>>;
  setShowFieldLevelValidation: Dispatch<SetStateAction<boolean>>;
  setMaskedEmail: Dispatch<SetStateAction<string>>;
  onCloseModal: VoidFunction | undefined;
  setIsResendCode: Dispatch<SetStateAction<boolean>>;

  reset: () => void;
};

export const AccountViewContext = createContext<AccountViewProviderType>(
  {} as AccountViewProviderType
);

type AccountViewProviderProps = {
  showHeaderAndFooterProp: boolean;
  onCloseModal: VoidFunction | undefined;
  children: ReactNode;
};

export function AccountViewProvider({
  onCloseModal,
  showHeaderAndFooterProp,
  children,
}: AccountViewProviderProps) {
  const { onCloseNotification } = useContext(SignInFlowTypeContext);

  const [view, setView] = useState<Views>('login');
  const [userName, setUserName] = useState('');
  const [autoSubmitEmail, setAutoSubmitEmail] = useState(false);
  const [maskedEmail, setMaskedEmail] = useState<string>('');
  const [userMaskedPhoneNumber, setUserMaskedPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [disableSignIn, setDisableSignIn] = useState(true);
  const [showHeaderAndFooter, setShowHeaderAndFooter] = useState(showHeaderAndFooterProp);
  const [openEmailSent, setOpenEmailSent] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [showFieldLevelValidation, setShowFieldLevelValidation] = useState(false);
  const [loginFlow, setLoginFlow] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [isResendCode, setIsResendCode] = useState(false);

  function reset() {
    onCloseNotification();
    setAutoSubmitEmail(false);
    setLoading(false);
    setDisableSignIn(true);
    setIsResendCode(false);
  }

  return (
    <AccountViewContext.Provider
      value={{
        view,
        userName,
        autoSubmitEmail,
        maskedEmail,
        userMaskedPhoneNumber,
        loading,
        disableSignIn,
        showHeaderAndFooter,
        openEmailSent,
        isModal,
        loginFlow,
        rememberMe,
        isResendCode,
        setView,
        setUserName,
        setAutoSubmitEmail,
        setUserMaskedPhoneNumber,
        setLoading,
        reset,
        setDisableSignIn,
        setShowHeaderAndFooter,
        setOpenEmailSent,
        setIsModal,
        setLoginFlow,
        setRememberMe,
        onCloseModal,
        setMaskedEmail,
        showFieldLevelValidation,
        setShowFieldLevelValidation,
        setIsResendCode,
      }}
    >
      {children}
    </AccountViewContext.Provider>
  );
}

export default AccountViewProvider;
